<template>
  <base-popup
    v-model="dialog"
    title="Добавить существующие документы"
    :icon="UtilGetIcon('view-LocalDocumentEdit')"
    @save="$emit('save', selected)"
    :width="width"
    :height="height"
  >
    <list-document
      :value="dataSource"
      :showDelete="false"
      :notShowActions="['add']"
      :search.sync="search"
      :show-add-in-table="false"
      @input="(value) => (this.selected = value)"
      @onRefresh="init"
      :isSelect="true"
      :selectedItems="selectedItems"
    ></list-document>
  </base-popup>
</template>

<script>
import LocalDocumentService from "../../services/LocalDocumentService";
import ListEditMixin from "../../mixins/ListEditMixin";
import BasePopup from "../../layouts/BasePopup.vue";
import ListDocument from "./ListDocument.vue";

export default {
  name: "view-PopupDocument",
  components: {
    BasePopup,
    ListDocument,
  },
  mixins: [ListEditMixin],
  props: {
    value: Boolean,
    filter: Array,
    width: String,
    height: String,
    selectedItems: { Array, default: () => [] },
  },
  data: () => {
    return {
      loading: true,
      apiService: LocalDocumentService,
      source: null,
      dataSource: [],
      selected: [],
      search: "",
    };
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        if (value) {
          this.init();
        }
        this.search = null;
        this.$emit("input", value);
      },
    },
  },

  async mounted() {
    // await this.init({ published: true });
  },
  methods: {
    init() {
      this.baseInit({ query: { published: true } });
    },
  },
};
</script>
